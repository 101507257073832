export function isCurrentlyAtHomeTab(): boolean {
  try {
    const searchUrl = window.location.href
    const url = new URL(searchUrl)
    const params = new URLSearchParams(url.search)
    return params.has('pinned') && params.get('pinned') === 'true'
  } catch (e) {
    return false
  }
}
