import { defineStore } from 'pinia'

export const useNewsToggleStore = defineStore('newsToggle', {
  state: () => ({
    newsToggle: true,
  }),
  actions: {
    updateNewsToggle() {
      this.newsToggle = !this.newsToggle
    },
  },
  persistedState: {
    persist: true,
  },
})
