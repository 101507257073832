import { useDoodleStore } from '~/store/DoodleStore'
let doodleStore: any
const totalDoodles = '24'

function isDateChanged(lastDoodleShowDate: Date, currentDate: Date) {
  return (
    new Date(
      Date.UTC(lastDoodleShowDate.getFullYear(), lastDoodleShowDate.getMonth(), lastDoodleShowDate.getDate())
    ) < new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))
  )
}

function doodleImage() {
  return '/doodles/Doodle' + doodleStore.currentDoodleIndex + '.png'
}

function initializeDoodleIndex() {
  doodleStore = useDoodleStore()
  if (doodleStore.lastDoodleShowDate === '') {
    doodleStore.lastDoodleShowDate = new Date().toString()
  }
  if (doodleStore.currentDoodleIndex === '') {
    doodleStore.currentDoodleIndex = String(1)
  }
  doodleStore.doodleImage = doodleImage()
  resetOrIncrementCurrentDoodleIndex()
}

function resetOrIncrementCurrentDoodleIndex() {
  const currentDate = new Date()
  const lastDoodleShowDate = new Date(doodleStore.lastDoodleShowDate)
  const currentDoodleIndex = doodleStore.currentDoodleIndex
  if (isDateChanged(lastDoodleShowDate, currentDate)) {
    if (currentDoodleIndex === totalDoodles) {
      doodleStore.currentDoodleIndex = String(1)
    } else {
      doodleStore.currentDoodleIndex = String(parseInt(currentDoodleIndex) + 1)
    }
    doodleStore.doodleImage = doodleImage()
    doodleStore.lastDoodleShowDate = currentDate.toString()
  }
}

const updateDoodleStore = () => {
  initializeDoodleIndex()
}

export { updateDoodleStore }
