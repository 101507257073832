import { defineStore } from 'pinia'
import { useMediaQuery } from '@vueuse/core'
import { sendMixpanelEvent, setProfileProperty } from '~/utils/host-browser-apis'

export enum SettingsThemeOption {
  dark = 'dark',
  light = 'light',
  auto = 'auto',
}

const LIGHT_THEME_ONLY_PAGES = ['advertise', 'contact', 'privacy-policy', 'partnerships', 'terms']

export type MixpanelThemeConfig = 'dark' | 'light' | `auto-${'dark' | 'light'}`

export const useThemeStore = defineStore(
  'themeStore',
  () => {
    const theme = ref<`${SettingsThemeOption}`>('light')
    const isDarkmodeEnabledSystemWide = useMediaQuery('(prefers-color-scheme: dark)')

    const isPageLightModeOnly = computed(() => {
      const currentRoute = useRoute()
      return LIGHT_THEME_ONLY_PAGES.includes(currentRoute.path)
    })
    const getDarkModeClass = computed<`${SettingsThemeOption}`>(() => {
      if (isPageLightModeOnly.value) return 'light'
      if (theme.value === 'light') return 'light'
      if (theme.value === 'auto' && !isDarkmodeEnabledSystemWide.value) return 'light'
      return 'dark'
    })
    const getMixpanelEventName = computed<MixpanelThemeConfig>(() => {
      if (theme.value === 'light') return 'light'
      if (theme.value === 'auto' && !isDarkmodeEnabledSystemWide.value) return 'auto-light'
      if (theme.value === 'auto' && isDarkmodeEnabledSystemWide.value) return 'auto-dark'
      return 'dark'
    })

    watch([theme, isDarkmodeEnabledSystemWide], async () => {
      document.documentElement.className = getDarkModeClass.value
      await sendMixpanelEvent('NtpSetting_Changed', {
        setting_name: 'ntp_theme',
        setting_value: getMixpanelEventName.value,
      })
      setProfileProperty('ntp_theme', getMixpanelEventName.value)
    })

    const updateTheme = (newTheme: `${SettingsThemeOption}`) => {
      if (isPageLightModeOnly.value) {
        theme.value = 'light'
      }
      theme.value = newTheme
    }

    return { theme, updateTheme, getMixpanelEventName }
  },
  {
    persistedState: {
      persist: true,
    },
  }
)
