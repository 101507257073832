import { datadogRum } from '@datadog/browser-rum'

export default function useDatadogRumClient(): void {
  onBeforeMount(() => {
    if (!process.client) return
    const config = useRuntimeConfig()

    datadogRum.init({
      applicationId: '3fccc1da-f662-48c3-bdbe-af3b88ef375b',
      clientToken: 'pub5d8c1bf1672c2bf18600f16527707ffd',
      site: config.public.datadogSite || 'datadoghq.com',
      service: config.public.datadogService || 'onenews',
      env: process.env.NODE_ENV,
      version: config.public.datadogVersion || 'unknown',
      sessionSampleRate: 0.01,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  })
}
