import { useDocumentVisibility } from '@vueuse/core'
import { getNtpUrl, getOneNewsVersion, getTypeTag } from '~/utils/host-browser-apis/analytics/helper'
import { getHostBrowserAppVersion } from '~/utils/host-browser-apis/getHostBrowserAppVersion'

declare global {
  interface Window {
    ayManagerEnv: any
    assertive: any
    isPageVisible: boolean
    assertiveYieldAnalytics: any
  }
}

export type AyLogImpressionEvent = {
  auctionId?: string
  impression_time: number
  timestamp: number
  version: string
  entityId: string
  clientUUID: string
  sessionUUID: string
  session_start: number
  pageViewUUID: string
  protocol: string
  host: string
  pathname: string
  pathname_split: string[]
  referrer: null | string
  utm: null | string
  acquisition: Record<string, unknown>
  entryPathname: string
  impressionCount: number
  pageViewCount: number
  pageView_impressionCount: number
  pageView_errorCount: number
  client_referrer: null | string
  client_utm: null | string
  client_entryPathname: string
  client_impressionCount: number
  client_pageViewCount: number
  client_sessionCount: number
  window: {
    innerWidth: number
    innerHeight: number
    scrollY: number
  }
  networkInformation: {
    type: string
    effectiveType: string
    downlink: number
    rtt: number
    saveData: boolean
  }
  vitals: Record<string, unknown>
  prebid_timeout: number
  prebid_version: string
  prebid: Record<string, unknown>
  userState: null | string
  layout: null | string
  experiments: any[]
  yieldManager: {
    versionId: number
    versionName: string
    deployId: string
    percentage: number
    isSplitTest: boolean
    isPreview: boolean
    entityId: string
  }
  content: {
    headline: null | string
    author: null | string
    datePublished: null | string
    dateModified: null | string
  }
  revenueBias: number
  timeZone: string
  pageView: {
    isInitialPageView: boolean
  }
  topics: any[]
  impressionUUID: string
  slotPreviousHighestBids: (null | number)[]
  pageView_refreshCount: number
  source: string
  slotId: string
  adUnitPath: string
  meta: {
    externalId: string
    publisherId: number
  }
  unfilled: boolean
  highestPreBid: null | number
  highestPreBid_partner: null | string
  mediaType: string
  creative_width: number
  creative_height: number
  preBidWon: boolean
  timeToRespond: null | number
  ivt: {
    category: number
  }
}

export type AssertiveYieldAnalyticsOptions = {
  /* 1 = all sessions are tracked, 0.5 = 50% of the sessions are tracked... */
  sampleRate: number
  integrations: {
    webAnalytics: boolean
  }
  logUnfilled: boolean
  custom: Record<string, unknown> & {
    userState: unknown
    layout: unknown
    custom_1: unknown
    custom_2: unknown
    custom_3: unknown
  }
  useHistoryChangeTrigger: boolean
}

export type AssertiveYieldOptions = {
  mode: 'production' | 'test'
  debug: boolean
  entityId: string
  analytics: AssertiveYieldAnalyticsOptions
  onAdFulfilled: (ad: AyLogImpressionEvent) => Promise<unknown>
}

interface AssertiveLogImpressionEvent extends Event {
  data?: { payload: AyLogImpressionEvent }
}

const injectAYSdk = (analyticsProps: any) => {
  if (window.assertive && window.ayManagerEnv) {
    return {
      ayManagerEnv: window.ayManagerEnv,
      assertive: window.assertive,
    }
  }

  const entityId = 'sRm8pLMYisn9D7DyN'

  useHead({
    script: [
      {
        src: `https://${entityId}.ay.delivery/manager/${entityId}`,
        type: 'text/javascript',
        referrerpolicy: 'no-referrer-when-downgrade',
      },
    ],
  })

  const assertiveYieldDefaultOptions = {
    entityId,
    debug: true,
  }

  const assertiveYieldAnalyticsDefaultOptions = {
    integrations: {
      webAnalytics: true,
    },
    logUnfilled: true,
    custom: {
      layout: getNtpUrl(),
      userState: null,
      custom_1: analyticsProps.type_tag,
      custom_2: analyticsProps.onenews_version,
      custom_3: analyticsProps.host_browser_app_version,
    },
    useHistoryChangeTrigger: true,
  }

  window.assertiveYieldAnalytics = window.assertiveYieldAnalytics || {
    ...assertiveYieldAnalyticsDefaultOptions,
  }
  window.assertive = window.assertive || { ...assertiveYieldDefaultOptions }
  window.ayManagerEnv = window.ayManagerEnv || { cmd: [] }

  return {
    ayManagerEnv: window.ayManagerEnv,
    assertive: window.assertive,
  }
}

export function useAssertiveYield(options?: Partial<AssertiveYieldOptions>): void {
  if (!process.client) return

  const previousCalledAction = ref<string | null>(null)
  window.addEventListener('assertive_logImpression', async (e: AssertiveLogImpressionEvent) => {
    try {
      const payload = e?.data?.payload as AyLogImpressionEvent

      const { unfilled, auctionId } = payload
      const isNewRequest = previousCalledAction.value !== auctionId

      if (options?.onAdFulfilled && !unfilled && auctionId && isNewRequest) {
        previousCalledAction.value = auctionId
        await options.onAdFulfilled(payload)
      }
    } catch (e) {}
  })

  onMounted(() => {
    window.ayManagerEnv.cmd.push(() => {
      window.ayManagerEnv.changePage()
    })
  })

  onBeforeMount(async () => {
    if (!process.client) return
    window.ayManagerEnv = window.ayManagerEnv || { cmd: [] }
    const analyticsProps = {
      onenews_version: getOneNewsVersion(),
      host_browser_app_version: await getHostBrowserAppVersion(),
      type_tag: await getTypeTag(),
    }
    injectAYSdk(analyticsProps)

    window.isPageVisible = document.visibilityState === 'visible'
    if (window.isPageVisible) {
      window.ayManagerEnv.cmd.push(() => {
        window.ayManagerEnv.dispatchManualEvent()
      })
    }
  })

  const pageVisibility = useDocumentVisibility()
  watch(pageVisibility, (value) => {
    const isPageVisible = value === 'visible'
    window.isPageVisible = isPageVisible

    if (isPageVisible) {
      window.ayManagerEnv.cmd.push(() => {
        window.ayManagerEnv.changePage()
        window.ayManagerEnv.dispatchManualEvent()
      })
    }
  })

  return window.ayManagerEnv
}

/**
 * Assertive Yield only looks for divs when it first loads,
 * for SPAs or elements that interactively change, we need to reevaluate the page
 * using the `changePage` and `dispatchManualEvent` functions suggested from
 * their docs.
 **/
export function useAssertiveYieldReevaluate() {
  if (!window.ayManagerEnv) {
    useAssertiveYield()
  }

  window.ayManagerEnv.cmd.push(() => {
    window.ayManagerEnv.changePage()
  })
}

export default useAssertiveYield
