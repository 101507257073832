import { parse } from 'cookie-es'
import type { SuperProperties } from '~/utils/host-browser-apis/analytics/index.types'

export enum NtpLbVersion {
  CloudflarePages = 'a',
  AWS = 'b',
}

export function getLoadBalancerSessionFromCookies(): SuperProperties['ntp_lb_session'] {
  const cookies = parse(document.cookie)
  if (cookies.LB_SESSION === NtpLbVersion.AWS) {
    return 'aws'
  }

  if (cookies.LB_SESSION === NtpLbVersion.CloudflarePages) {
    return 'cloudflare_pages'
  }

  return 'unknown'
}
