import { getHostApis } from './getHost'
import type { BaseInstallInfoConfig } from '~/types'

export async function getInstallInfo(): Promise<BaseInstallInfoConfig | undefined> {
  const { onelaunch } = getHostApis()
  const onelaunchAPI = onelaunch?.getInstallInfo
  if (onelaunchAPI) {
    return onelaunchAPI()
  }

  return undefined
}
