import { default as admGC8UUWg49jMeta } from "/opt/buildhome/repo/pages/adm.vue?macro=true";
import { default as indexwDzKzQa81mMeta } from "/opt/buildhome/repo/pages/advertise/index.vue?macro=true";
import { default as ncIWePpaUvLNMeta } from "/opt/buildhome/repo/pages/combo/a/nc.vue?macro=true";
import { default as index2j9n8RhyylMeta } from "/opt/buildhome/repo/pages/contact/index.vue?macro=true";
import { default as g24Be09j3wkMeta } from "/opt/buildhome/repo/pages/g.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexlara922cLgMeta } from "/opt/buildhome/repo/pages/p/index.vue?macro=true";
import { default as indexHsTXuUdmWvMeta } from "/opt/buildhome/repo/pages/p/nc/index.vue?macro=true";
import { default as indexjpbdpldPmWMeta } from "/opt/buildhome/repo/pages/p/nc/tab/a/index.vue?macro=true";
import { default as index5gsRyykftCMeta } from "/opt/buildhome/repo/pages/partnerships/index.vue?macro=true";
import { default as indexadQClU00T1Meta } from "/opt/buildhome/repo/pages/privacy-policy/index.vue?macro=true";
import { default as indexYcHIZyHrmHMeta } from "/opt/buildhome/repo/pages/summary/index.vue?macro=true";
import { default as indexACXpVw0FC1Meta } from "/opt/buildhome/repo/pages/tab/v5/a/nc/index.vue?macro=true";
import { default as ncyckybauMk6Meta } from "/opt/buildhome/repo/pages/tab/v5/nc.vue?macro=true";
import { default as indexwMTkQLgOTvMeta } from "/opt/buildhome/repo/pages/terms/index.vue?macro=true";
import { default as ay_45testSQQ0q6dbiBMeta } from "/opt/buildhome/repo/pages/v5/ay-test.vue?macro=true";
import { default as indexos47eCoBZdMeta } from "/opt/buildhome/repo/pages/v5/index.vue?macro=true";
import { default as indexQ8mmslqUQ7Meta } from "/opt/buildhome/repo/pages/v5/nc/index.vue?macro=true";
import { default as ncCzBTnC7vdUMeta } from "/opt/buildhome/repo/pages/v6/nc.vue?macro=true";
import { default as indexhwNSftvM3KMeta } from "/opt/buildhome/repo/pages/v8/index.vue?macro=true";
export default [
  {
    name: admGC8UUWg49jMeta?.name ?? "adm",
    path: admGC8UUWg49jMeta?.path ?? "/adm",
    meta: admGC8UUWg49jMeta || {},
    alias: admGC8UUWg49jMeta?.alias || [],
    redirect: admGC8UUWg49jMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/adm.vue").then(m => m.default || m)
  },
  {
    name: indexwDzKzQa81mMeta?.name ?? "advertise",
    path: indexwDzKzQa81mMeta?.path ?? "/advertise",
    meta: indexwDzKzQa81mMeta || {},
    alias: indexwDzKzQa81mMeta?.alias || [],
    redirect: indexwDzKzQa81mMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/advertise/index.vue").then(m => m.default || m)
  },
  {
    name: ncIWePpaUvLNMeta?.name ?? "combo-a-nc",
    path: ncIWePpaUvLNMeta?.path ?? "/combo/a/nc",
    meta: ncIWePpaUvLNMeta || {},
    alias: ncIWePpaUvLNMeta?.alias || [],
    redirect: ncIWePpaUvLNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/combo/a/nc.vue").then(m => m.default || m)
  },
  {
    name: index2j9n8RhyylMeta?.name ?? "contact",
    path: index2j9n8RhyylMeta?.path ?? "/contact",
    meta: index2j9n8RhyylMeta || {},
    alias: index2j9n8RhyylMeta?.alias || [],
    redirect: index2j9n8RhyylMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: g24Be09j3wkMeta?.name ?? "g",
    path: g24Be09j3wkMeta?.path ?? "/g",
    meta: g24Be09j3wkMeta || {},
    alias: g24Be09j3wkMeta?.alias || [],
    redirect: g24Be09j3wkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/g.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlara922cLgMeta?.name ?? "p",
    path: indexlara922cLgMeta?.path ?? "/p",
    meta: indexlara922cLgMeta || {},
    alias: indexlara922cLgMeta?.alias || [],
    redirect: indexlara922cLgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/p/index.vue").then(m => m.default || m)
  },
  {
    name: indexHsTXuUdmWvMeta?.name ?? "p-nc",
    path: indexHsTXuUdmWvMeta?.path ?? "/p/nc",
    meta: indexHsTXuUdmWvMeta || {},
    alias: indexHsTXuUdmWvMeta?.alias || [],
    redirect: indexHsTXuUdmWvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/p/nc/index.vue").then(m => m.default || m)
  },
  {
    name: indexjpbdpldPmWMeta?.name ?? "p-nc-tab-a",
    path: indexjpbdpldPmWMeta?.path ?? "/p/nc/tab/a",
    meta: indexjpbdpldPmWMeta || {},
    alias: indexjpbdpldPmWMeta?.alias || [],
    redirect: indexjpbdpldPmWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/p/nc/tab/a/index.vue").then(m => m.default || m)
  },
  {
    name: index5gsRyykftCMeta?.name ?? "partnerships",
    path: index5gsRyykftCMeta?.path ?? "/partnerships",
    meta: index5gsRyykftCMeta || {},
    alias: index5gsRyykftCMeta?.alias || [],
    redirect: index5gsRyykftCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: indexadQClU00T1Meta?.name ?? "privacy-policy",
    path: indexadQClU00T1Meta?.path ?? "/privacy-policy",
    meta: indexadQClU00T1Meta || {},
    alias: indexadQClU00T1Meta?.alias || [],
    redirect: indexadQClU00T1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexYcHIZyHrmHMeta?.name ?? "summary",
    path: indexYcHIZyHrmHMeta?.path ?? "/summary",
    meta: indexYcHIZyHrmHMeta || {},
    alias: indexYcHIZyHrmHMeta?.alias || [],
    redirect: indexYcHIZyHrmHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/summary/index.vue").then(m => m.default || m)
  },
  {
    name: indexACXpVw0FC1Meta?.name ?? "tab-v5-a-nc",
    path: indexACXpVw0FC1Meta?.path ?? "/tab/v5/a/nc",
    meta: indexACXpVw0FC1Meta || {},
    alias: indexACXpVw0FC1Meta?.alias || [],
    redirect: indexACXpVw0FC1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/tab/v5/a/nc/index.vue").then(m => m.default || m)
  },
  {
    name: ncyckybauMk6Meta?.name ?? "tab-v5-nc",
    path: ncyckybauMk6Meta?.path ?? "/tab/v5/nc",
    meta: ncyckybauMk6Meta || {},
    alias: ncyckybauMk6Meta?.alias || [],
    redirect: ncyckybauMk6Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/tab/v5/nc.vue").then(m => m.default || m)
  },
  {
    name: indexwMTkQLgOTvMeta?.name ?? "terms",
    path: indexwMTkQLgOTvMeta?.path ?? "/terms",
    meta: indexwMTkQLgOTvMeta || {},
    alias: indexwMTkQLgOTvMeta?.alias || [],
    redirect: indexwMTkQLgOTvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: ay_45testSQQ0q6dbiBMeta?.name ?? "v5-ay-test",
    path: ay_45testSQQ0q6dbiBMeta?.path ?? "/v5/ay-test",
    meta: ay_45testSQQ0q6dbiBMeta || {},
    alias: ay_45testSQQ0q6dbiBMeta?.alias || [],
    redirect: ay_45testSQQ0q6dbiBMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/v5/ay-test.vue").then(m => m.default || m)
  },
  {
    name: indexos47eCoBZdMeta?.name ?? "v5",
    path: indexos47eCoBZdMeta?.path ?? "/v5",
    meta: indexos47eCoBZdMeta || {},
    alias: indexos47eCoBZdMeta?.alias || [],
    redirect: indexos47eCoBZdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/v5/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ8mmslqUQ7Meta?.name ?? "v5-nc",
    path: indexQ8mmslqUQ7Meta?.path ?? "/v5/nc",
    meta: indexQ8mmslqUQ7Meta || {},
    alias: indexQ8mmslqUQ7Meta?.alias || [],
    redirect: indexQ8mmslqUQ7Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/v5/nc/index.vue").then(m => m.default || m)
  },
  {
    name: ncCzBTnC7vdUMeta?.name ?? "v6-nc",
    path: ncCzBTnC7vdUMeta?.path ?? "/v6/nc",
    meta: ncCzBTnC7vdUMeta || {},
    alias: ncCzBTnC7vdUMeta?.alias || [],
    redirect: ncCzBTnC7vdUMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/v6/nc.vue").then(m => m.default || m)
  },
  {
    name: indexhwNSftvM3KMeta?.name ?? "v8",
    path: indexhwNSftvM3KMeta?.path ?? "/v8",
    meta: indexhwNSftvM3KMeta || {},
    alias: indexhwNSftvM3KMeta?.alias || [],
    redirect: indexhwNSftvM3KMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/v8/index.vue").then(m => m.default || m)
  }
]