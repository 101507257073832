import { getHostApis } from '~/utils/host-browser-apis'

export function incrementProfileProperty(name: string, value: any): void {
  const { shift, chrome, onelaunch } = getHostApis()

  const shiftAPI = shift?.analytics?.updateNumericProfileProperties
  if (shiftAPI) {
    return shiftAPI({ [name]: value })
  }

  const oneLaunchAPI = onelaunch?.addProfileProperty
  if (oneLaunchAPI) {
    oneLaunchAPI(name, value)
    return
  }

  const chromeAPI = chrome?.runtime?.addProfileProperty
  if (chromeAPI) {
    chromeAPI(name, value)
  }
}
