import { defineStore } from 'pinia'

export const useDoodleStore = defineStore('doodleStore', {
  state: () => ({
    lastDoodleShowDate: '',
    currentDoodleIndex: '1',
    doodleImage: '',
  }),
  persistedState: {
    persist: true,
  },
})
