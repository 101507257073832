<template>
  <div class="d-flex align-items-center justify-content-center">
    <div v-if="displayAlert" class="alert alert-danger" role="alert">
      Sorry, something went wrong! Reloading the page in
      {{ countdown }} seconds...
    </div>
    <div v-else class="alert alert-secondary">
      Please wait. You will be redirected in {{ countdown }} seconds...
    </div>
  </div>
</template>

<script setup>
  const displayAlert = ref(false)
  const countdown = ref(3)

  const props = defineProps({
    // eslint-disable-next-line vue/require-default-prop
    error: Object,
  })

  if (props.error.message.includes('Failed to fetch dynamically imported module')) {
    const reloadCount = parseInt(localStorage.getItem('reloadCount') || '0', 10)
    if (reloadCount < 3) {
      localStorage.setItem('reloadCount', String(reloadCount + 1))
      displayAlert.value = true
      const intervalId = setInterval(() => {
        countdown.value = countdown.value - 1
        if (countdown.value === 0) {
          clearInterval(intervalId)
          window.location.reload(true)
        }
      }, 1000)
    } else {
      localStorage.removeItem('reloadCount')
      displayAlert.value = false
      const intervalId = setInterval(() => {
        countdown.value = countdown.value - 1
        if (countdown.value === 0) {
          clearInterval(intervalId)
          window.location.href = 'https://search.yahoo.com/'
        }
      }, 1000)
    }
  }
</script>

<style lang="scss" scoped>
  .d-flex {
    min-height: 100vh;
  }

  .alert {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0.25rem;
  }
</style>
