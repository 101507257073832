import version from '../../../version.json'
import {
  getCurrentThemeSetting,
  getNewsContentToggleState,
  getNtpPath,
  getNtpUrl,
  getTypeTag,
} from '../analytics/helper'
import { getLoadBalancerSessionFromCookies } from './getLoadbalancerSessionFromCookies'
import type { SuperProperties } from '~/utils/host-browser-apis/analytics/index.types'

/**
 * Properties that will be propagated with every event.
 */
export const getSuperProperties = async (): Promise<SuperProperties> => ({
  ntp_type_tag: await getTypeTag(),
  ntp_window_active: window.isPageVisible || document.visibilityState === 'visible',
  ntp_url: getNtpUrl(),
  onenews_version: version?.version || 'unknown',
  ntp_layout: getNtpPath(),
  ntp_page_type: window.location.pathname.includes('summary') ? 'summary' : 'homepage',
  ntp_news_content_toggle: getNewsContentToggleState(),
  ntp_theme: getCurrentThemeSetting(),
  ntp_lb_session: getLoadBalancerSessionFromCookies(),
  ntp_is_user_idle: window.isUserIdle,
})
