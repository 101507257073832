import { getHostApis } from '~/utils/host-browser-apis'

export function setProfileProperty(name: string, value: any): void {
  const { shift, chrome, onelaunch } = getHostApis()

  const shiftAPI = shift?.analytics?.setProfileProperties
  if (shiftAPI) {
    return shiftAPI({ [name]: value })
  }

  const oneLaunchAPI = onelaunch?.setProfileProperty
  if (oneLaunchAPI) {
    oneLaunchAPI(name, JSON.stringify(value))
    return
  }

  const chromeAPI = chrome?.runtime?.setProfileProperty
  if (chromeAPI) {
    chromeAPI(name, JSON.stringify(value))
  }
}
