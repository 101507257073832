<template>
  <NuxtPage />
</template>

<script setup lang="ts">
  import useDatadogRumClient from './composables/third-party/useDatadogRum.client'
  import useHomeTabFavicon from './composables/useHomeTabFavicon'
  import { useDetectUserActivity } from './composables/useDetectUserActivity'
  import { updateDoodleStore } from 'assets/js/doodle'
  import { isCurrentlyAtHomeTab } from '~/utils/host-browser-apis'
  import * as analytics from '~/utils/host-browser-apis/analytics'

  const isHomeTab = isCurrentlyAtHomeTab()
  const faviconData = isHomeTab
    ? [
        {
          rel: 'icon',
          type: 'image/png',
          href: useHomeTabFavicon(),
        },
      ]
    : [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#000000' },
      ]

  useHead({
    htmlAttrs: {
      lang: 'en',
    },
    link: [
      ...faviconData,
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto&display=swap',
      },
    ],
  })
  const { setLocale } = useI18n()

  onMounted(() => {
    useDetectUserActivity()
  })

  useAssertiveYield()

  onBeforeMount(() => {
    if (!window.analytics) {
      window.analytics = analytics
    }
    useDatadogRumClient()
    updateDoodleStore()
  })

  if (process.client) {
    const AVAILABLE_TRANSLATIONS = ['en', 'de', 'fr']
    const language = navigator.language.slice(0, 2)

    if (AVAILABLE_TRANSLATIONS.includes(language)) {
      setLocale(language)
    } else {
      setLocale('en')
    }
    const route = useRoute()
    if (route.name !== 'summary') {
      useTaboolaAdsDispose()
    }
  }
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style>
  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;
  }
</style>
