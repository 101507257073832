import { getTypeTag } from '~/assets/js/utils'

declare global {
  interface Window {
    TRC: undefined | unknown
    _taboola: Array<unknown>
  }
}

const injectTaboolaHeadScripts = () =>
  useHead(
    {
      script: [
        {
          key: 'taboola-loader',
          type: 'text/javascript',
          textContent: `
        !function (e, f, u, i) { if (!document.getElementById(i)){ e.async = 1; e.src = u; e.id = i; f.parentNode.insertBefore(e, f); } }
        (document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/rebasetechinc-onelaunch/loader.js', 'tb_loader_script');
        if(window.performance && typeof(window.performance.mark) == 'function') {window.performance.mark('tbl_ic');}`,
          async: true,
        },
      ],
    },
    { mode: 'client' }
  )

interface UseTaboolaAds {
  type?: 'homepage' | 'article'
}
export const useTaboolaAds = (options: UseTaboolaAds) => {
  onBeforeMount(() => {
    if (window.TRC) return

    injectTaboolaHeadScripts()
    const typeTag = getTypeTag()

    const commonProperties = { custom_tracking: `sub_id=${typeTag}` }

    window._taboola = window._taboola || []

    if (options?.type === undefined || options?.type === 'homepage') {
      window._taboola.push({ homepage: 'auto', ...commonProperties })
      return
    }

    if (options?.type === 'article') {
      window._taboola.push({ article: 'auto', ...commonProperties })
    }
  })
}

export const useTaboolaAdsDispose = () => {
  const handleUnload = () => {
    if (window.TRC) {
      window._taboola = window._taboola || []
      window._taboola.push({ flush: true })
    }

    window.removeEventListener('beforeunload', handleUnload)
  }

  onBeforeMount(() => {
    window.addEventListener('beforeunload', handleUnload)
  })
}
