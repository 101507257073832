import type { NtpAdEvent, NtpNewsEvent, NtpSettingChanged, NtpShortcutEvent } from './index.types'
import { getSuperProperties } from './getSuperProperties'
import { getHostApis } from '~/utils/host-browser-apis'

export async function sendMixpanelEvent(
  name: 'query' | 'QueryNonMonetized_Submitted',
  data: { location: 'ntp'; trigger: 'keyboard-return' | 'suggestion-press'; monetized?: boolean }
): Promise<void>
export async function sendMixpanelEvent(
  name: `NtpAd_${'Rendered' | 'Clicked'}`,
  data: NtpAdEvent
): Promise<void>
export async function sendMixpanelEvent(
  name: `NtpNewsTile_${'Clicked' | 'Rendered'}`,
  data: NtpNewsEvent
): Promise<void>
export async function sendMixpanelEvent(
  name: 'NtpOriginalNewsButton_Clicked',
  data: NtpNewsEvent
): Promise<void>
export async function sendMixpanelEvent(
  name: `NtpShortcutAd_${'Clicked' | 'Rendered'}`,
  data: NtpShortcutEvent
): Promise<void>
export async function sendMixpanelEvent(name: 'NtpSetting_Changed', data: NtpSettingChanged): Promise<void>
export async function sendMixpanelEvent(name: 'NtpPage_Viewed', data?: never): Promise<void>
export async function sendMixpanelEvent(name: 'NtpWeather_Clicked', data?: never): Promise<void>
export async function sendMixpanelEvent(name: 'NtpMoreNews_Loaded', data?: never): Promise<void>
export async function sendMixpanelEvent(name: any, data: any) {
  const superProperties = await getSuperProperties()
  const eventData = {
    ...superProperties,
    ...data,
  }

  if (process.client) {
    window.mixpanelEventCallLog = window.mixpanelEventCallLog || []
    window.mixpanelEventCallLog.push({ name, eventData })
  }

  const { shift, chrome, onelaunch } = getHostApis()
  const shiftAPI = shift?.analytics?.track
  if (shiftAPI) {
    return shiftAPI(name, eventData)
  }

  const oneLaunchAPI = onelaunch?.sendEvent
  if (oneLaunchAPI) {
    oneLaunchAPI(name, eventData)
    return
  }

  const chromeAPI = chrome?.runtime?.sendEvent
  if (chromeAPI) {
    chromeAPI(name, eventData)
  }
}
