import { defineNuxtPlugin } from 'nuxt/app'
import type { Pinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

export default defineNuxtPlugin((nuxtApp) => {
  ;(nuxtApp.$pinia as Pinia).use(
    createPersistedStatePlugin({
      // plugin options goes here
      persist: false,
    })
  )
})
