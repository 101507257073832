export type Favicon = '/home-favicon-light.ico' | '/home-favicon-dark.ico'

function getHomeTabFavicon(): Favicon {
  if (window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)')?.matches) {
    return '/home-favicon-light.ico'
  }

  return '/home-favicon-dark.ico'
}

export default function useHomeTabFavicon(): Ref<Favicon | undefined> {
  return useState<Favicon | undefined>('favicon', getHomeTabFavicon)
}
