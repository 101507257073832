export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    beforeMount: (el, { value }) => {
      el.clickOutsideEvent = (event: Event) => {
        if (!(el === event.target || el.contains(event.target))) {
          value()
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unmounted: (el) => {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })
})
